<template>
  <div v-if="user" class="flex-grow-1">
    <preloader v-if="status.getting" message="Please wait..." />

    <v-row v-if="hasProfile && !status.getting" class="d-none d-sm-flex">
      <v-col
        cols="12 profile-cover dark justify-center align-center mb-6"
        :style="{backgroundImage: `url(${ backgroundCover ? backgroundCover : 'http://placeimg.com/1440/343/nature/grayscale'})`}"
      >
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="profile && !status.getting" class="profile-details" cols="12">
        <v-row>
          <v-col cols="12">
            <v-btn
              @click="$router.go(-1)"
              color="white"
              outlined
              small
            >
              <v-icon small left>mdi-chevron-left</v-icon>
              <span>Back to search results</span>
            </v-btn>
          </v-col>

          <v-col>

            <v-card max-width="220" class="shadow-lg rounded-lg mx-auto mx-sm-0">
              <v-card-text v-if="user" class="pa-3">
                <UserPhoto :id="user.userid" size="100%" tile photoSize="small" :blur="!$store.getters['pricing/withActiveSub']" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-sheet color="transparent" :min-height="$vuetify.breakpoint.xs ? 'auto' : '245'">
              <v-card v-if="!$store.getters['pricing/withActiveSub']">
                <custom-alert type="warning" border="left" text>
                  Subscribe <router-link :to="{ name: 'Subscription' }">here</router-link> to view more details or invite talent to your job listing.
                </custom-alert>
              </v-card>

              <div
                :style="{fontSize: $vuetify.breakpoint.xs ? '25px' : '42px'}"
                class="font-weight-bold heading-font text-capitalize"
                :class="$vuetify.breakpoint.xs ? 'primary--text' : 'white--text'"
              >{{ formatName(`${user.firstName} ${user.lastName}`) }}</div>
              
              <template v-if="$store.getters['pricing/withActiveSub']">
                <div v-if="profile.linkedin" class="mb-2">
                  <a
                    :class="$vuetify.breakpoint.xs ? '' : 'white--text'"
                    :href="'//' + profile.linkedin"
                    class="text-decoration-none"
                    target="_blank"
                  >
                    <v-icon :color="$vuetify.breakpoint.xs ? 'default' : 'white'" small left>mdi-linkedin</v-icon>
                    <span class="underline-on-hover">
                      {{ profile.linkedin }}
                    </span>
                  </a>
                </div>

                <div v-if="user.email" class="mb-2">
                  <a
                    :class="$vuetify.breakpoint.xs ? '' : 'white--text'"
                    :href="`mailto:${user.email}`"
                    class="text-decoration-none"
                  >
                    <v-icon :color="$vuetify.breakpoint.xs ? 'default' : 'white'" small left>mdi-email-outline</v-icon>
                    <span class="underline-on-hover">
                      {{ user.email }}
                    </span>
                  </a>
                </div>

                <div v-if="profile.phone" class="mb-2">
                  <a
                    :class="$vuetify.breakpoint.xs ? '' : 'white--text'"
                    :href="`tel:${profile.phone}`"
                    class="text-decoration-none"
                  >
                    <v-icon :color="$vuetify.breakpoint.xs ? 'default' : 'white'" small left>
                      mdi-phone-dial-outline
                    </v-icon>
                    <span class="underline-on-hover">
                      {{ profile.phone }}
                    </span>
                  </a>
                </div>

                <div v-if="user.address">
                  <span class="d-flex align-center" :class="$vuetify.breakpoint.xs ? '' : 'white--text'">
                    <v-icon :color="$vuetify.breakpoint.xs ? 'default' : 'white'" left small>mdi-map-marker-outline</v-icon>
                    {{ [user.address.locality, user.address.administrative_area_level_1].join(', ') }}
                  </span>
                </div>
              </template>
            </v-sheet>
            
          </v-col>
          <v-col cols="12" md="3" align="center">
            <template v-if="currentUser && currentUser.role == 'employer' && $store.getters['pricing/withActiveSub']">
              <div class="text-center mb-5">
                <FavouriteTeacher :user="user"/>
              </div>

              <v-btn 
                class="py-6 px-7 rounded-lg"
                color="accent gradient"
                @click="inviteJob(user.userid)"
              >
                Invite To A Job
              </v-btn>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :to="{
                      name: 'UserAvailability',
                      params: { id: user.userid },
                    }"
                    class="ml-2 px-3 rounded-lg"
                    v-bind="attrs"
                    color="white"
                    v-on="on"
                    small
                    fab
                  >
                    <v-icon>mdi-calendar-outline</v-icon>
                  </v-btn>
                </template>
                <span>Availability</span>
              </v-tooltip>
            </template>
            <template v-if="currentUser && currentUser.role == 'jobseeker'">
              <v-btn 
                :to="{ name: 'MyProfile' }"
                class="py-6 px-7 rounded-lg"
                color="accent gradient"
              >
                Edit Details
              </v-btn>
            </template>
          </v-col>
        </v-row>

        <!-- BOTTOM CONTENT -->

        <div class="mt-3">
          <!-- BIO -->
          <div class="mb-5">
            <div :class="title">Short Bio:</div>
            <div class="text-pre-line">{{ profile.aboutMe }}</div>
          </div>

          <template v-if="$store.getters['pricing/withActiveSub']">
            <!-- VIDEO -->
            <v-card v-if="profile.videoPresentation" class="shadow-lg pa-1 rounded-lg full-width mb-5" :max-width="$vuetify.breakpoint.xs ? '300' : '480'">
              <v-card-text class="pa-2">
                <VideoEmbed
                  css="embed-responsive-21by9 video-presentation"
                  :src="profile.videoPresentation"
                ></VideoEmbed>
              </v-card-text>
            </v-card>

            <!-- RESUME -->
            <div>
              <template v-if="profile.resume">
                <span :class="$text.label">Resume:</span> <a @click="$downloadFile('attachments', profile.resume)" class="info--text underline-on-hover mr-5">{{ profile.resume | fileName }}</a>
              </template>

              <template v-if="attachments.length">
                <span :class="$text.label" class="mr-2">Other attachments:</span>

                <a
                  v-for="attachment in attachments"
                  :key="attachment.id"
                  @click="$downloadFile('attachments', attachment.file)"
                  class="info--text underline-on-hover mr-3"
                  outlined
                >
                  {{ attachment.file | fileName }}
                </a>
              </template>
            </div>
          </template>

          <v-divider class="my-5"></v-divider>

          <!-- skills section -->
          
          <h3 :class="title">Teaching Skills & Experience</h3>

          <div v-if="profile.teachingLvl" class="mb-3"><span :class="$text.label">Years of experience:</span> {{ profile.teachingLvl }}</div>
          <div v-if="profile.schoolWorkedAt" class="mb-3"><span :class="$text.label">Schools worked at:</span> {{ profile.schoolWorkedAt.join(', ') }}</div>
          
          <div v-if="profile.position && profile.position.length" class="mb-3"><span :class="$text.label">Roles qualified in:</span> {{ profile.position.join(', ') }}</div>
          
          <div v-if="profile.skillset &&  profile.skillset.length" class="mb-3">
            <div :class="$text.label">Skill Set / Areas Trained In:</div> 
            <div class="d-flex gap-5 flex-wrap">
              <v-chip
                v-for="(skill, index) in profile.skillset"
                :key="index"
                rounded
                small
              >
                {{ skill }}
              </v-chip>
            </div>
          </div>
          
          <div v-if="profile.willingSkills &&  profile.willingSkills.length" class="mb-3">
            <div :class="$text.label">Other Areas Able to Supervise:</div> 
            <div class="d-flex gap-5 flex-wrap">
              <v-chip
                v-for="(skill, index) in profile.willingSkills"
                :key="index"
                rounded
                small
              >
                {{ skill }}
              </v-chip>
            </div>
          </div>

          <template v-if="education.length">
            <v-divider class="my-5"></v-divider>

            <h3 class="text-h5 font-weight-black mb-5 primary--text">Tertiary / VET Qualifications</h3>

            <v-sheet v-if="education.length" max-width="600" class="mb-5">
              <v-card outlined>
                <v-simple-table outlined dense>
                  <tbody>
                    <tr v-for="value in education" :key="value.id">
                      <td>{{ value.fieldOfStudy }}</td>
                      <td>{{ value.schoolName }}</td>
                      <td>{{ value.yearStart }} - {{ value.yearEnd }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-sheet>
          </template>
          
          <h3 class="text-h5 font-weight-black mb-5">Registrations / Key Credentials</h3>

          <div v-if="profile.teacherRegistration" class="mb-2">
            <v-icon left small>mdi-check</v-icon> Teacher Registration
          </div>
          
          <div v-if="profile.workingWithChildrenCheck" class="mb-2">
            <v-icon left small>mdi-check</v-icon> Working With Children Check
          </div>

          <div v-if="profile.relevantRegistrationDetails" class="my-3">
            {{ profile.relevantRegistrationDetails }}
          </div>
          
          <template v-if="experience && experience.length">
            <h3 :class="title">Recent Teaching Experience</h3>

            <v-card 
              v-for="item in experience" 
              :key="item.id" 
              class="mb-5" 
              max-width="600" 
              outlined
            >
              <v-simple-table outlined dense>
                <tbody>
                  <tr>
                    <td width="30%">Company name</td>
                    <td>: {{ item.schoolName }}</td>
                  </tr>
                  <tr>
                    <td width="30%">Period</td>
                    <td>: {{ item.start | monthYear }} to {{ item.end | monthYear }}</td>
                  </tr>
                  <tr>
                    <td width="30%">Position</td>
                    <td>: {{ item.position }}</td>
                  </tr>
                  <tr>
                    <td width="30%">Region</td>
                    <td>: {{ item.region }}</td>
                  </tr>
                  <tr>
                    <td width="30%">Role</td>
                    <td>: {{ item.overview }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </template>

          <template v-if="referees && referees.length && $store.getters['pricing/withActiveSub']">
            <v-divider class="my-5"></v-divider>
            
            <h3 :class="title">Professional Referees</h3>

            <div
              v-for="(ref, i) in referees"
              :key="i"
              class="mb-5"
            >
              <div>
                <span class="font-weight-bold">{{ ref.name }} {{ ref.lastName }}:</span> {{ ref.position }} - {{ ref.organisation}}
              </div>
              <div>
                {{ ref.email }} - {{ ref.phone }}
              </div>
            </div>
          </template>
        </div>
      </v-col>
    </v-row>

    <!-- INVITE DIALOG -->
    <invite-dialog :user="user" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import InviteDialog from '@/views/job/InviteDialog'

export default {
  name: "TalentProfile",

  metaInfo: {
    title: "TalentProfile",
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    InviteDialog
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      data: [],
      deleting: false,
      title: 'text-h5 font-weight-black mb-5 primary--text'
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.users.jobseekerAccount,
      talentFavorite: (state) => state.user.talentFavorite,
      profile: (state) => state.profile.profile,
      attachments: (state) => state.profile.attachments,
      experience: (state) => state.profile.experience,
      backgroundCover: (state) => state.profile.backgroundCover,
      hasProfile: (state) => state.profile.hasProfile,
      profileStatus: (state) => state.profile.status,
      education: (state) => state.profile.education,
      referees: state => state.profile.referees,
      currentUser: (state) => state.user.user,
      status: (state) => state.profile.status
    }),
  },

  watch: {
    'user' : function(val) {
      if(val) {
        this.checkFavorite(val)
      }
    },
  },
  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('profile', ['getProfile', 'getBackgroundPhotoUrl']),
    ...mapActions('users', ['getTalentAccount', 'getUserByUid']),
    ...mapActions('user', ['checkFavorite']),

    ...mapActions('favorite', [
        'setTalentFavorite',
        'getTalentFavorites'
    ]),

    ...mapActions('invite',[
      'getOpenJob',
      'inviteTalent',
      'checkInvitedTalent'
    ]),

    formatName(name) {
      if (this.$store.getters['pricing/withActiveSub']) {
        return name
      }
      else {
        if(name){
          return `${name.substr(0,5)}...`
        }
      }
    },

    inviteJob(userid) {
      this.getOpenJob()
      this.checkInvitedTalent({ talentId: userid })
      this.$store.commit('invite/setUpDialog', true)
    },

    favorite(favorite){
      if(favorite){
        Promise.all([this.setTalentFavorite({update: true, favoriteObj: favorite})])
        .then(() => {
          this.checkFavorite(this.user)
        })
      }else{
        Promise.all([this.setTalentFavorite(this.user)])
        .then(() => {
          this.checkFavorite(this.user)
        })
      }
    },

    fileIcon(type, color = false) {
       let icons = [
        {
          icon: 'mdi-file-image-outline',
          color: 'green accent-4',
          types: [
            'image/jpeg',
            'image/png'
          ]
        },
        {
          icon: 'mdi-file-pdf-outline',
          color: 'red accent-3',
          types: [
            'application/pdf',
          ]
        },
        {
          icon: 'mdi-file-code-outline',
          color: 'amber darken-2',
          types: [
            'image/svg+xml',
          ]
        },
        {
          icon: 'mdi-zip-box-outline',
          color: 'blue-grey darken-1',
          types: [
            'application/x-zip-compressed',
          ]
        },
      ]

      let icon = icons.find(i => {
        return i.types.includes(type)
      })

      if (color) return icon ? icon.color : 'blue accent-3'
      else return icon ? icon.icon : 'mdi-file-document-outline'
    },

  },

  mounted() {
    this.$store.commit('profile/resetAll')

    Promise.all([
      this.getUserByUid(this.$route.params.id)
    ])
    .then(() => {
      this.getProfile(this.$route.params.id)
    })

    this.getTalentAccount(this.$route.params.id)
    this.getBackgroundPhotoUrl(this.$route.params.id)
    this.getTalentFavorites()
    
    if (this.user) this.checkFavorite(this.user)
  },
}
</script>

<style scoped lang="scss">
.profile-cover:before {
  background-color: var(--v-primary-base);
  opacity: 0.8;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
}
.profile-cover {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  height: 400px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat !important;
}
.profile-social-divider {
  width: 100%;
}
.profile-details {
  z-index: 4;
}
.profile-skills {
  width: 650px;
}
.profile-accordion .accordion-header .v-expansion-panel-header__icon {
  will-change: box-shadow !important;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 100%;
  padding: 4px;
}
.profile-accordion .icon-chev--text {
  will-change: box-shadow !important;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 100%;
  padding: 4px;
}
.video-presentation {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.opacity-0{
  opacity: 0;
}
// .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
</style>